import React from "react";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";

export interface FoodCardProps {
  image?: FluidObject;
  name: string;
  description: string[];
}

export function FoodCard(props: FoodCardProps) {
  return (
    <Container>
      {props.image && <FoodImg fluid={props.image} />}
      <Name>{props.name}</Name>
      {props.description.map(d => (
        <Description key={d}>{d}</Description>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: inline-block;
  width: 370px;
  margin: 32px 10px;
`;

const FoodImg = styled(Img)`
  width: 370px;
  height: 220px;
`;

const Name = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.34px;
  text-align: center;
  color: #212529;
  margin-top: 8px;
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.27px;
  text-align: center;
  color: #495057;
  margin-top: 4px;
  padding: 0 16px;
`;
