import React from "react";

import { useMediaQueryPlatform } from "../../components/Shared/MediaQuery";

import { IndexPage as Desktop } from "./desktop";
import { IndexPage as Mobile } from "./mobile";
import Layout from "../../components/Layout";

export default function Container() {
  const platform = useMediaQueryPlatform();
  const [mounted, setMounted] = React.useState(false);

  // prevents ssr flash for mismatched dark mode
  setTimeout(() => {
    setMounted(true);
  });

  if (!mounted) {
    return (
      <Layout>
        <Desktop />
      </Layout>
    );
  }

  switch (platform) {
    case "desktop":
      return (
        <Layout>
          <Desktop />
        </Layout>
      );
    case "mobile":
      return (
        <Layout>
          <Mobile />
        </Layout>
      );
  }
}
