import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";

const RedText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.31px;
  color: #c92a2a;
`;

export function SectionHeadline(proos: { text: string }) {
  return <RedText>{proos.text}</RedText>;
}

interface SectionHeaderProps {
  en: string;
  kr: string;
  linkText: string;
  linkPath: string;
}

interface StaticQueryProps {
  rightArrowImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export function SectionHeader(props: SectionHeaderProps) {
  const data: StaticQueryProps = useStaticQuery(graphql`
    query {
      rightArrowImage: file(relativePath: { eq: "right_arrow.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <SectionHeadline text={props.en} />
      <div>
        <SectionText>{props.kr}</SectionText>
        <RightArrow fluid={data.rightArrowImage.childImageSharp.fluid} />
        <LinkText to={props.linkPath}>{props.linkText}</LinkText>
      </div>
      <Border />
    </div>
  );
}

const SectionText = styled.h1`
  display: block;
  float: left;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.54px;
  color: #212529;
  padding: 0;
  margin: 0;
`;

const RightArrow = styled(Img)`
  display: inline-block;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 3px;
`;

const LinkText = styled(Link)`
  display: block;
  float: right;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.34px;
  color: #c92a2a;
  margin-top: 14px;
`;

const Border = styled.div`
  height: 1px;
  background-color: #dee2e6;
  margin-top: 6px;
`;
