import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";

import Section from "../../../components/Section";
import { SectionHeader } from "./shared";
import { FoodCard, FoodCardProps } from "../../../components/FoodCard/Mobile";

interface StaticQueryProps {
  porkSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  sausageSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  mixedSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  assortedSoupRiceImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  boildPorkSliceAndSoupImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  sausageImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export function Section3() {
  const imgData: StaticQueryProps = useStaticQuery(graphql`
    query {
      porkSoupRiceImage: file(relativePath: { eq: "pork_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sausageSoupRiceImage: file(relativePath: { eq: "sausage_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mixedSoupRiceImage: file(relativePath: { eq: "mixed_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      assortedSoupRiceImage: file(relativePath: { eq: "assorted_soup_rice.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boildPorkSliceAndSoupImage: file(relativePath: { eq: "boild_pork_slice_and_soup.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sausageImage: file(relativePath: { eq: "sausage.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const foodData: FoodCardProps[] = [
    {
      image: imgData.porkSoupRiceImage.childImageSharp.fluid,
      name: "돼지국밥",
      description: ["최고급 생고기만 고집하여 부드럽게 삶아내어", "든든하게 먹을 수 있는 국밥"],
    },
    {
      image: imgData.sausageSoupRiceImage.childImageSharp.fluid,
      name: "순대국밥",
      description: ["속이 꽉 찬 수제순대와 담백하게 삶아낸", "고기가 들어가 진한 국물맛을 내는 알찬 국밥"],
    },
    {
      image: imgData.mixedSoupRiceImage.childImageSharp.fluid,
      name: "섞어국밥",
      description: ["고기와 내장을 함께 끓여내어 깔끔하면서도", "풍부한 맛을 내는 국밥", "(내장만 넣은 내장국밥 가능)"],
    },
  ];

  return (
    <Section>
      <ContentContainer>
        <ContentWrapper>
          <SectionHeader en="MENU" kr="메뉴" linkText="전체보기" linkPath="/menu/" />
          <div>
            {foodData.map(data => (
              <FoodCard key={data.name} image={data.image} name={data.name} description={data.description} />
            ))}
          </div>
        </ContentWrapper>
      </ContentContainer>
    </Section>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  padding: 100px 0px;
`;

const ContentWrapper = styled.div`
  width: 312px;
  height: 100%;
  margin: 0 auto;
`;
