import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";

import Section from "../../../components/Section";
import { SectionHeadline } from "./shared";

interface StaticQueryProps {
  holeFoodImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export function Section2() {
  const data: StaticQueryProps = useStaticQuery(graphql`
    query {
      holeFoodImage: file(relativePath: { eq: "hole_food.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Section>
      <ContentContainer>
        <ContentWrapper>
          <SectionHeadline text="수복돼지국밥 소개" />
          <Title>
            <p>대표자 공수복의</p>
            <p>이름을 걸고</p>
            <p>맛과 정성을 다한</p>
            <p>
              <b>부산·경남의 대표음식</b>
            </p>
            <p>
              <b>돼지국밥!</b>
            </p>
          </Title>
          <ContentText>
            정성으로 우려낸 수복돼지국밥의 육수는 최고급 국내산 돼지고기만을 사용하여 담백하고 시원한 맛을 내며 신선한
            고기를 자주 삶아 육즙가득한 고기를 제공합니다.
          </ContentText>
          <ContentText>
            다른 첨가물을 가미하지 않고 신선한 국내산 돼지사골로 우려내어 순수한 돼지사골맛으로 남녀노소 누구나 편하게
            즐길 수 있습니다.
          </ContentText>
          <RightImage fluid={data.holeFoodImage.childImageSharp.fluid} />
        </ContentWrapper>
      </ContentContainer>
      <RedTextContainer>
        <p>아기들까지 먹일 수 있는</p>
        <p> 건강하고 착한음식!</p>
        <p>
          <b>언제나 맛과 정성을 다하는</b>
        </p>
        <p>
          <b>수복돼지국밥이 되겠습니다.</b>
        </p>
      </RedTextContainer>
    </Section>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  padding: 100px 0px;
`;

const ContentWrapper = styled.div`
  width: 312px;
  height: 100%;
  margin: 0 auto;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.54px;
  color: #212529;
  margin-top: 8px;
`;

const ContentText = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -0.34px;
  color: #212529;
  margin: 24px 0;
`;

const RightImage = styled(Img)`
  width: 312px;
  height: 300px;
`;

const RedTextContainer = styled.div`
  width: 100%;
  height: 176px;
  background-color: #c92a2a;
  padding: 32px 0;

  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.37px;
  text-align: center;
  color: #ffffff;
`;
