import React from "react";
import styled from "styled-components";

import Section from "../../../components/Section";
import { SectionHeader } from "./shared";
import { oneLineTrim } from "common-tags";

const naverKey = "hnrq0lgpf7";
const marker = (x: string, y: string) => `type:d|size:mid|color:Orange|pos:${x} ${y}`;
const maps = {
  yeonsan: {
    src: oneLineTrim`
      https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors
      ?crs=EPSG:4326
      &w=370
      &h=463
      &center=129.0868676,35.1857657
      &level=17
      &X-NCP-APIGW-API-KEY-ID=${naverKey}
      &public_transit=true
      &markers=${marker("129.0868676", "35.1857657")}
    `,
    name: "부산본점(연산동)",
    address: "부산광역시 연제구 고분로 41-1, 2층",
    call: "051-866-3349",
    href: "https://naver.me/5iYlTIP7",
  },
  myoungjang: {
    src: oneLineTrim`
      https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors
      ?crs=EPSG:4326
      &w=370
      &h=463
      &center=129.1022214,35.2056195
      &level=17
      &X-NCP-APIGW-API-KEY-ID=${naverKey}
      &public_transit=true
      &markers=${marker("129.1022214", "35.2056195")}
    `,
    name: "동래명장점",
    address: "부산광역시 동래구 반송로 290, 1층",
    call: "051-959-4753",
    href: "https://naver.me/5I4KiwoF",
  },
  geojae: {
    src: oneLineTrim`
      https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors
      ?crs=EPSG:4326
      &w=370
      &h=463
      &center=128.7119864,35.0065428
      &level=14
      &X-NCP-APIGW-API-KEY-ID=${naverKey}
      &markers=${marker("128.7119864", "35.0065428")}
    `,
    name: "한화벨버디어점",
    address: "거제시 장목면 거제북로 2501-40, 고메이",
    call: "055-951-4205",
    href: "https://naver.me/5MPl5exL",
  },
};

function MapCard(props: { value: typeof maps[keyof typeof maps] }) {
  return (
    <MapCardContainer>
      <a href={props.value.href} target="_blank">
        <MapImage src={props.value.src} />
      </a>
      <MapText>
        <b>{props.value.name}</b>
      </MapText>
      <MapText>{props.value.address}</MapText>
      <MapText>{props.value.call}</MapText>
    </MapCardContainer>
  );
}

export function Section4() {
  return (
    <Section>
      <ContentContainer>
        <ContentWrapper>
          <SectionHeader en="MAP" kr="찾아오시는 길" linkText="자세히 보기" linkPath="/map/" />
          <MapCard value={maps.yeonsan} />
          <CenteredMapCard>
            <MapCard value={maps.myoungjang} />
          </CenteredMapCard>
          <MapCard value={maps.geojae} />
        </ContentWrapper>
      </ContentContainer>
    </Section>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 0 100px 0;
`;

const ContentWrapper = styled.div`
  width: 1170px;
  height: 100%;
  margin: 0 auto;
`;

const MapCardContainer = styled.div`
  display: inline-block;
  margin: 32px 0 0 0;
`;

const CenteredMapCard = styled.div`
  display: inline-block;
  margin: 0 30px;
`;

const MapImage = styled.img`
  width: 370px;
  height: 463px;
  margin-bottom: 24px;
`;

const MapText = styled.p`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -0.34px;
  color: #212529;
`;
