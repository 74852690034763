import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";

import Section from "../../../components/Section";

interface StaticQueryProps {
  backgroundImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  downArrowImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export function Section1() {
  const data: StaticQueryProps = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "main_background.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      downArrowImage: file(relativePath: { eq: "down_arrow.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Section>
      <BackgroundImageContainer>
        <BackgroundImg fluid={data.backgroundImage.childImageSharp.fluid} />
      </BackgroundImageContainer>
      <ContentContainer>
        <Content>
          <SubTitle>마음까지 따뜻해지는 한 그릇</SubTitle>
          <Title>수복돼지국밥</Title>
          <ButtonGroup>
            <TransparentButton
              onClick={() => {
                window.scrollTo({ top: 640, behavior: "smooth" });
              }}
            >
              브랜드 소개
            </TransparentButton>
            <Link to="/menu/">
              <WhiteButton>메뉴보기</WhiteButton>
            </Link>
          </ButtonGroup>
        </Content>
        <ScrollIndicator>
          <IndicatorText>더 보기</IndicatorText>
          <IndicatorImg fluid={data.downArrowImage.childImageSharp.fluid} />
        </ScrollIndicator>
      </ContentContainer>
    </Section>
  );
}

const BackgroundImageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 640px;
  z-index: -1;
`;

const BackgroundImg = styled(props => <Img {...props} />)`
  height: inherit;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 640px;
  padding-top: 231px;
`;

const Content = styled.div`
  width: 232px;
  margin: auto;
`;

const SubTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  text-align: center;
  color: #f8f9fa;
`;

const Title = styled.h1`
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.82px;
  text-align: center;
  color: #ffffff;
`;

const ButtonGroup = styled.button`
  margin-top: 24px;
`;

const TransparentButton = styled.button`
  width: 108px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #ffffff;
  background-color: rgba(255, 255, 255, 0.16);
  margin-right: 8px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: -0.31px;
  text-align: center;
  color: #f8f9fa;
`;

const WhiteButton = styled.button`
  width: 108px;
  height: 40px;
  border-radius: 6px;
  background-color: #ffffff;
  margin-left: 8px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: -0.31px;
  text-align: center;
  color: #212529;
`;

const ScrollIndicator = styled.div`
  width: 65px;
  height: 126px;
  background-color: #c92a2a;
  margin: 150px auto 0 auto;
  padding: 12px 0;
`;

const IndicatorText = styled.p`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: -0.24px;
  text-align: center;
  color: #ffffff;
`;

const IndicatorImg = styled(Img)`
  width: 18px;
  height: 38px;
  margin: 12px auto auto auto;
`;
